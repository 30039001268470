import React from "react";
import { QuoteWrapper, QuoteText, Attribution, Company } from "./styled";
import { Container, ContainerSize } from "../../core/Container/styled";

interface QuoteProps {
  quote: string;
  attribution?: string;
  company?: string;
  isFull?: boolean;
  shrink?: boolean;
}

const Quote = ({
  quote,
  attribution,
  company,
  isFull = false,
  shrink = false,
}: QuoteProps) => {
  return (
    <Container isFull={isFull} size="small">
      <QuoteWrapper>
        <QuoteText shrink={shrink}>{quote}</QuoteText>
        {!!attribution && <Attribution>{attribution}</Attribution>}
        {!!company && <Company>{company}</Company>}
      </QuoteWrapper>
    </Container>
  );
};

export default Quote;
