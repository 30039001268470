import React from "react";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../../settings";
import { Wrapper, WordMark } from "./styled";

interface LogoProps {
  color: string;
  hideWordMark?: boolean;
  zIndex?: number;
}

const Logo = ({ color, hideWordMark, zIndex }: LogoProps) => (
  <Wrapper style={{ zIndex }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 327 269.5">
      <g id="mark">
        <polygon
          points="163.45 0 0.09 94.03 0 121.33 70.05 161.78 163.45 107.85 256.86 161.78 326.91 121.33 326.91 94.37 163.45 0"
          fill={color}
        />
        <polygon
          points="0.09 175.13 70.14 215.58 163.54 161.65 256.95 215.58 327 175.14 327 229.06 256.95 269.5 163.54 215.58 70.14 269.5 0.09 229.06 0.09 175.13"
          fill={color}
        />
      </g>
    </svg>
    <MediaQuery minWidth={Breakpoints.tabletPortrait.upper}>
      {!hideWordMark && <WordMark color={color}>Wigwam Visual</WordMark>}
    </MediaQuery>
  </Wrapper>
);

export default Logo;
