import React from "react";
import { Wrapper, Burger } from "./styled";

interface HamburgerProps {
  color: string;
  isOpen: boolean;
  toggleNav: () => void;
}

const Hamburger = ({ color, toggleNav, isOpen }: HamburgerProps) => (
  <Wrapper onClick={toggleNav}>
    <Burger color={color} isOpen={isOpen} />
    <Burger color={color} isOpen={isOpen} />
  </Wrapper>
);

export default Hamburger;
