import React, { useState } from "react";
import { NAVIGATION_MAPPING } from "../../../mapping";
import {
  StyledLink,
  NavItem,
  NavItems,
  MobileNavigationWrapper,
  MobileNavigationInnerWrapper,
  ContactFooter,
} from "./styled";
import { Match } from "@reach/router";
import MediaQuery from "react-responsive";
import Hamburger from "../Hamburger";
import { Timing } from "../../../settings";
import { Transition } from "react-transition-group";
import { MOBILE_NAVIGATION_BREAKPOINT } from "./styled";
import { fade } from "../../../transition";
import { ContactLink, ContactLinkWrapper } from "../Footer/styled";
import { smoothScrollTo } from "../../../util/scrollTo";
import { useStaticQuery, graphql } from "gatsby";

interface NavigationProps {
  color: string;
  showMobileNav: boolean;
  setShowMobileNav: (showNav: boolean) => void;
  footerOffset: number;
}

const Navigation = ({
  color,
  showMobileNav,
  setShowMobileNav,
}: NavigationProps) => {
  const renderNavItems = (itemKeys: string[]) => {
    return itemKeys.map(itemKey => {
      const item = NAVIGATION_MAPPING[itemKey];
      return (
        <Match key={item.url} path={item.url === "/" ? `/` : `${item.url}/*`}>
          {({ match }) => {
            return (
              <NavItem key={item.url}>
                <StyledLink
                  exit={{
                    length: 0.25,
                    trigger: ({ exit, node }) =>
                      fade({ exit, node, direction: "out" }),
                  }}
                  entry={{
                    length: 0.25,
                    trigger: ({ exit, node }) =>
                      fade({ exit, node, direction: "in" }),
                    delay: 0.25,
                  }}
                  isMatch={!!match}
                  to={item.url}
                  color={color}
                >
                  {item.label}
                </StyledLink>
              </NavItem>
            );
          }}
        </Match>
      );
    });
  };

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "content" }
          name: { eq: "meta-data" }
        }
      ) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                email
                telephone
              }
            }
          }
        }
      }
    }
  `);

  const {
    email,
    telephone,
  } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  return (
    <nav>
      <MediaQuery maxWidth={MOBILE_NAVIGATION_BREAKPOINT.lower}>
        <Hamburger
          isOpen={showMobileNav}
          toggleNav={() => setShowMobileNav(!showMobileNav)}
          color={color}
        />
        <Transition
          in={showMobileNav}
          timeout={{ enter: 0, exit: Timing.default }}
        >
          {status => (
            <MobileNavigationWrapper transitionStatus={status}>
              <MobileNavigationInnerWrapper>
                <NavItems>
                  {renderNavItems(
                    Object.keys(NAVIGATION_MAPPING).filter(
                      itemKey => itemKey !== "contact"
                    )
                  )}
                </NavItems>
                <ContactFooter>
                  <ContactLinkWrapper>
                    <ContactLink
                      href={`mailto:${email}?subject=Project Enquiry`}
                    >
                      {email}
                    </ContactLink>
                  </ContactLinkWrapper>
                  <ContactLinkWrapper>
                    <ContactLink href={`tel:${telephone}`}>
                      {telephone}
                    </ContactLink>
                  </ContactLinkWrapper>
                </ContactFooter>
              </MobileNavigationInnerWrapper>
            </MobileNavigationWrapper>
          )}
        </Transition>
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_NAVIGATION_BREAKPOINT.upper}>
        <NavItems>
          {renderNavItems(
            Object.keys(NAVIGATION_MAPPING).filter(
              itemKey => itemKey !== "index"
            )
          )}
          <NavItem>
            <StyledLink
              onClick={() => smoothScrollTo(document.body.scrollHeight)}
              as="span"
              color={color}
            >
              Contact
            </StyledLink>
          </NavItem>
        </NavItems>
      </MediaQuery>
    </nav>
  );
};

export default Navigation;
