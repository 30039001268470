const supportsNativeSmoothScroll =
  typeof window !== "undefined"
    ? "scrollBehavior" in document.documentElement.style
    : false;

export const smoothScrollTo = (top: number) => {
  if (supportsNativeSmoothScroll) {
    window.scrollTo({
      top,
      behavior: "smooth",
    });
  } else {
    window.scrollTo(0, top);
  }
};
