import React, { useRef, useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import GlobalStyle from "../style/global";
import { Helmet } from "react-helmet";
import Header from "../components/core/Header";
import Footer from "../components/core/Footer";
import { Color } from "../settings";

interface LayoutProps {
  title: string;
  children: React.ReactNode;
  headerColor?: string;
  headerBackgroundColor?: string;
}

export const Layout = ({
  title,
  children,
  headerColor,
  headerBackgroundColor,
}: LayoutProps) => {
  const [footerOffset, setFooterOffset] = useState<number>(0);

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "content" }
          name: { eq: "meta-data" }
        }
      ) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                openGraph {
                  image
                  title
                  description
                  keywords
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    image,
    title: ogTitle,
    description,
    keywords,
  } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter.openGraph;

  return (
    <>
      <Helmet title={`${title} – Wigwam Visual`}>
        <html lang="en" />
        <meta name="description" content={description} />
        <meta property="og:title" content={ogTitle} />
        <meta name="keywords" content={keywords}></meta>
        <meta
          property="og:image"
          content={`https://wigwamvisual.com/${image}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.wigwamvisual.com" />
        <link
          rel="preload"
          as="font"
          href="/font/HKGrotesk-Regular.woff2"
          crossOrigin="false"
          type="font/woff2"
        />
        <link
          rel="preload"
          as="font"
          href="/font/HKGrotesk-SemiBold.woff2"
          crossOrigin="false"
          type="font/woff2"
        />

        <link
          rel="preload"
          as="font"
          href="/font/HKGrotesk-Bold.woff2"
          crossOrigin="false"
          type="font/woff2"
        />
      </Helmet>
      <GlobalStyle />
      <Header
        color={!!headerColor ? headerColor : Color.black}
        backgroundColor={headerBackgroundColor}
        footerOffset={footerOffset}
      />
      {children}
      <Footer setFooterOffset={setFooterOffset} />
    </>
  );
};
