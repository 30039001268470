import React from "react";
import { IconProps } from "../../models";

const ArrowUp = ({ color }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.53 42.26">
    <polyline
      points="0 5.26 5.26 0 10.53 5.26"
      fill={color}
      className="arrowhead"
    />
    <line
      x1="5.26"
      y1="4.26"
      x2="5.26"
      y2="42.26"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      className="line"
    />
  </svg>
);

export default ArrowUp;
