import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Color } from "../../../settings";

interface ImageProps {
  image: string;
  focalPoint?: string;
  size?: "full" | "half";
  altText: string;
  style?: React.CSSProperties;
}

const Image = ({ image, size, ...props }: ImageProps) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { in: ["jpg", "png"] } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const match = useMemo(
    () =>
      data.allFile.edges.find(
        ({ node }) => image.split("/")[2] === node.relativePath
      ),
    [data, image]
  );

  if (!!match) {
    return (
      <Img
        fluid={match.node.childImageSharp.fluid}
        {...props}
        backgroundColor={Color.black}
      />
    );
  }

  return null;
};

export default Image;
