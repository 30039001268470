import styled from "styled-components";
import { Space, Color, Breakpoints } from "../../../settings";
import { breakpointUp } from "../../../util/breakpoint";

interface WrapperProps {
  backgroundColor?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;

  padding: ${Space.large.rem} 0;
  top: 0;
  // Ensure header doesn't grow when font loads
  min-height: 103px;
  width: 100%;

  background-color: ${p => p.backgroundColor ?? Color.white };
  z-index: 10;

  ${breakpointUp(Breakpoints.tabletPortrait.upper)`
    padding: ${Space.default.rem} 0;
  `};
`;

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoWrapper = styled.figure`
  max-width: 240px;
  margin: 0;
`;
