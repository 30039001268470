import React from "react";
import Quote from "../../core/Quote";

interface TextProps {
  quote: string;
}

const Text = ({ quote }: TextProps) => <Quote quote={quote} />;

export default Text;
