import React from "react";
import { ImageProps } from "../../../models";
import SmallImage from "../SmallImage";
import { Wrapper, Figure, Caption } from "./styled";

interface ImageWithCaptionProps {
  image: string;
  altText: string;
  caption: string;
  align: "left" | "right";
  textAlign: "top" | "middle" | "bottom";
}

const ImageWithCaption = ({
  image,
  altText,
  caption,
  align,
  textAlign,
}: ImageWithCaptionProps) => {
  return (
    <Wrapper textAlign={textAlign}>
      <Figure align={align}>
        <SmallImage
          image={image}
          size="half"
          altText={altText}
          focalPoint="50,50"
        />
      </Figure>
      <Caption>
        <p>{caption}</p>
      </Caption>
    </Wrapper>
  );
};

export default ImageWithCaption;
