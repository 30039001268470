import React, { useEffect, useRef } from "react";
import {
  StyledFooter,
  Tagline,
  TaglineWrapper,
  ContactLink,
  InnerWrapper,
  ContactWrapper,
  Address,
  Arrow,
  ArrowWrapper,
  ContactLinkWrapper,
  SocialWrapper,
} from "./styled";
import { Container } from "../Container/styled";
import Logo from "../Logo";
import { Color } from "../../../settings";
import ArrowUp from "../../icon/ArrowUp";
import { useStaticQuery, graphql } from "gatsby";
import Instagram from "./Instagram";
import Facebook from "./Facebook";
import { smoothScrollTo } from "../../../util/scrollTo";

interface FooterProps {
  setFooterOffset: (offset: number) => void;
}

const Footer = ({ setFooterOffset }: FooterProps) => {
  const ref = useRef(null);

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "content" }
          name: { eq: "meta-data" }
        }
      ) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                email
                telephone
                address
                facebook
                instagram
              }
            }
          }
        }
      }
    }
  `);

  const {
    email,
    telephone,
    address,
    facebook,
    instagram,
  } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  useEffect(() => {
    setFooterOffset(document.body.scrollHeight);
  }, []);

  return (
    <StyledFooter id="footer" ref={ref}>
      <Container>
        <InnerWrapper>
          <TaglineWrapper>
            <Logo color={Color.white} hideWordMark={true} />
            <Tagline>Let's build something.</Tagline>
          </TaglineWrapper>
          <ContactWrapper>
            <ContactLinkWrapper>
              <ContactLink href={`mailto:${email}?subject=Project Enquiry`}>
                {email}
              </ContactLink>
            </ContactLinkWrapper>
            <ContactLinkWrapper>
              <ContactLink href={`tel:${telephone}`}>{telephone}</ContactLink>
            </ContactLinkWrapper>
            <Address>{address}</Address>
            <SocialWrapper>
              <a href={facebook}>
                <Facebook />
              </a>
              <a href={instagram}>
                <Instagram />
              </a>
            </SocialWrapper>
          </ContactWrapper>
        </InnerWrapper>
      </Container>
      <ArrowWrapper onClick={() => smoothScrollTo(0)}>
        <Arrow>
          <ArrowUp color={Color.white} />
        </Arrow>
      </ArrowWrapper>
    </StyledFooter>
  );
};

export default Footer;
