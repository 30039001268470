import styled from "styled-components";
import { Easing, Color, Space, Weight } from "../../../settings";

interface WordMarkProps {
  color: string;
}

export const WordMark = styled.p<WordMarkProps>`
  margin-left: ${Space.default.rem};

  color: ${p => p.color};
  font-weight: ${Weight.bold};
  text-transform: uppercase;
  text-decoration: none;
  transition: transform ${Easing.default};
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;

    polygon {
      transition: fill ${Easing.slow};
    }
  }

  &:hover {
    ${WordMark} {
      transform: translateX(${Space.small.rem});
    }
  }
`;
