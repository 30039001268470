import Image from "./components/modules/Image";
import Quote from "./components/modules/Quote";
import ImageGrid from "./components/modules/ImageGrid";

import {
  ModuleType,
  ProjectFilterType,
  NavigationType,
  FocalPointType,
} from "./types";
import { NavigationModel } from "./models";
import ImageWithCaption from "./components/modules/ImageWithCaption";
import Video from "./components/modules/Video";
import Panorama from "./components/modules/Panorama";
import Text from "./components/modules/Text";
import IFrame from "./components/modules/IFrame";

export const MODULE_MAPPING: { [type in ModuleType]: React.ReactNode } = {
  image: Image,
  quote: Text,
  doubleImage: ImageGrid,
  tripleImage: ImageGrid,
  imageWithCaption: ImageWithCaption,
  video: Video,
  panorama: Panorama,
  testimonial: Quote,
  iframe: IFrame,
};

export const FILTER_LABEL_MAPPING: { [type in ProjectFilterType]: string } = {
  all: "All",
  interactive: "Interactive",
  still: "Still",
  animation: "Animation",
  concept: "Concept/Planning",
};

export const NAVIGATION_MAPPING: {
  [type in NavigationType]: NavigationModel;
} = {
  index: {
    url: "/",
    label: "Home",
  },
  studio: {
    url: "/studio",
    label: "Studio",
  },
  projects: { url: "/projects", label: "Projects" },
};

export const FOCAL_POINT_TO_PERCENTAGE: { [key in FocalPointType]: string } = {
  "top-left": "0% 0%",
  "top-center": "50% 0%",
  "top-right": "100% 0%",
  "center-left": "0% 50%",
  center: "50% 50%",
  "center-right": "100% 50%",
  "bottom-left": "0% 100%",
  "bottom-center": "50% 100%",
  "bottom-right": "100% 100%",
};
