import React from "react";
import QuoteComponent from "../../core/Quote";

interface QuoteProps {
  testimonials: any;
  testimonial: string;
}

const Quote = ({ testimonials, testimonial }: QuoteProps) => {
  const t = testimonials.filter(test => test.attribution === testimonial)[0];
  const quote = t.quote ?? undefined;
  const company = t.company ?? undefined;

  return (
    <QuoteComponent quote={quote} attribution={testimonial} company={company} />
  );
};

export default Quote;
