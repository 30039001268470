export interface BreakpointModel {
  lower: number;
  upper: number;
}

// We have these lower and upper bounds to ensure
// there is no overlap in our breakpoints e.g.
// @media screen and (min-width: 1024px)
// @media screen and (min-width: 1024px)
//
// maxWidth and breakpointDown should use the lower bound
// minWidth and breakpointUp should use the upper bound

export const createBreakpoint = (breakpoint: number): BreakpointModel => ({
  lower: breakpoint,
  upper: breakpoint + 1,
});
