import styled from "styled-components";
import { Space, Color, Easing } from "../../../settings";

interface BurgerProps {
  color: string;
  isOpen: boolean;
}

export const Burger = styled.div<BurgerProps>`
  height: 4px;
  width: 100%;

  background-color: ${(p) => p.color};
  transition: transform ${Easing.default}, width ${Easing.fast},
    background-color ${Easing.slow};

  & + & {
    margin-top: 8px;
  }

  &:nth-child(odd) {
    width: ${(p) => (p.isOpen ? "100%" : "80%")};
    transform: ${(p) =>
      p.isOpen ? "translateY(6px) rotateZ(45deg)" : "rotateZ(0)"};
  }

  &:nth-child(even) {
    transform: ${(p) =>
      p.isOpen ? "translateY(-6px) rotateZ(-45deg)" : "rotateZ(0)"};
  }
`;

export const Wrapper = styled.button`
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  width: 3.5rem;
  padding: 0;
  z-index: 20;

  background: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    ${Burger} {
      width: 100%;
    }
  }
`;
