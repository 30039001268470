import { Breakpoints } from "../settings";
import { css } from "styled-components";

const brkpnts: number[] = Object.keys(Breakpoints).map(
  breakpoint => Breakpoints[breakpoint]
);

export const breakpoint = (lowerBound: number, upperBound?: number) => {
  // If the index of the lower bound is 0, i.e. it's the smallest breakpoint
  // use 0 as a value instead
  const index = brkpnts.indexOf(lowerBound);
  const lowerIndex =
    index === 0 ? 0 : Breakpoints[Object.keys(Breakpoints)[index - 1]];

  // If only one breakpoint is provided switch the lower bound to
  // the preceding breakpoint and set the upper bound to the current lower bound
  const lower = upperBound ? lowerBound : lowerIndex;
  const upper = upperBound ? upperBound : lowerBound;

  return (...args: {}[]) => css`
    @media screen and (min-width: ${lower}px) and (max-width: ${upper}px) {
      ${css(...args)}
    }
  `;
};

export const breakpointDown = (upperBound: number) => (...args: {}[]) => css`
  @media screen and (max-width: ${upperBound}px) {
    ${css(...args)}
  }
`;

export const breakpointUp = (lowerBound: number) => (...args: {}[]) => css`
  @media screen and (min-width: ${lowerBound}px) {
    ${css(...args)}
  }
`;
