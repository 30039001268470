import { createBreakpoint } from "./util/createBreakpoint";

export const Color = {
  // Primary
  black: "#000",
  white: "#fff",
  // Secondary
  concrete: "#f2f2f2",
  darkConcrete: "#ccc",
  // Accents
  lightBuff: "#e3d6ba",
  darkBuff: "#a5987b",
  mutedPine: "#043933",
  darkenedPine: "#012C27",
};

export const zIndex = {
  base: 0,
  menu: 100,
};

export const Space = {
  xxSmall: {
    rem: `0.1rem`,
    number: 0.1,
  },
  xSmall: {
    rem: `0.25rem`,
    number: 0.25,
  },
  small: {
    rem: `0.5rem`,
    number: 0.5,
  },
  default: {
    rem: `1rem`,
    number: 1,
  },
  large: {
    rem: `1.75rem`,
    number: 1.75,
  },
  xLarge: {
    rem: `2.5rem`,
    number: 2.5,
  },
  xxLarge: {
    rem: `4rem`,
    number: 4,
  },
  xxxLarge: {
    rem: `8rem`,
    number: 8,
  },
};

export const Typography = {
  default: {
    rem: `1rem`,
  },
  medium: {
    rem: `1.25rem`,
  },
  large: {
    rem: `1.5rem`,
  },
  xLarge: {
    rem: `2rem`,
  },
};

export const Weight = {
  regular: 300,
  medium: 600,
  bold: 700,
};

export const Curves = {
  default: "cubic-bezier(.17,.67,.57,1)",
};

export const Timing = {
  fast: 0.125,
  default: 0.25,
  slow: 0.5,
  xSlow: 1,
};

export const Easing = {
  fast: `${Timing.fast}s ${Curves.default}`,
  default: `${Timing.default}s ${Curves.default}`,
  slow: `${Timing.slow}s ${Curves.default}`,
  xSlow: `${Timing.xSlow}s ${Curves.default}`,
};

export const Breakpoints = {
  // We have these lower and upper bounds to ensure
  // there is no overlap in our breakpoints e.g.
  // @media screen and (min-width: 1024px)
  // @media screen and (min-width: 1024px)
  //
  // maxWidth and breakpointDown should use the lower bound
  // minWidth and breakpointUp should use the upper bound
  //

  // Device
  mobile: createBreakpoint(480),
  mobileLarge: createBreakpoint(720),
  tabletPortrait: createBreakpoint(1024),
  desktop: createBreakpoint(1440),
  desktopLarge: createBreakpoint(1920),
};
