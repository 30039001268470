import styled from "styled-components";

export type ContainerSize = "small" | "medium" | "large";

interface ContainerProps {
  size?: ContainerSize;
  isFull?: boolean;
}

const SIZE_MAPPING = (size = "large") => {
  const map = { small: `740px`, medium: `1440px`, large: `1920px` };
  return map[size];
};

export const Container = styled.section<ContainerProps>`
  position: relative;

  width: ${p => (!!p.isFull ? "100%" : "90%")};
  margin: 0 auto;
  max-width: ${p => SIZE_MAPPING(p.size)};
`;
