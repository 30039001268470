import React, { useEffect, useState } from "react";
import pannellum from "../../../lib/pannellum.js";

import "./style.css";
import MediaQuery, { useMediaQuery } from "react-responsive";

interface PanoramaProps {
  coverImage: string;
  configUrl: string;
}

let myPannellum = null;

const Panorama = ({ configUrl }: PanoramaProps) => {
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const [scenes, setScenes] = useState(null);
  const id = Math.random()
    .toString(16)
    .slice(-4);

  const fetchScenes = async () => {
    const response = await fetch(configUrl);
    const json = await response.json();
    setScenes(json);
  };

  const init = () => {
    myPannellum = pannellum.viewer(id, scenes);
  };

  useEffect(() => {
    fetchScenes();
  }, []);

  useEffect(() => {
    if (!!scenes) {
      init();
    }
  }, [scenes]);

  return (
    <>
      <div
        id={id}
        style={{
          width: "100%",
          height: isPortrait ? "60vh" : "90vh",
        }}
      />
    </>
  );
};

export default Panorama;
