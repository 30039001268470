import React from "react";
import { Wrapper, ImageGridItem } from "./styled";
import SmallImage from "../SmallImage";
import { ImageProps } from "../../../models";

interface ImageGridProps {
  imageOne: ImageProps;
  imageTwo: ImageProps;
  imageThree?: ImageProps;
}

const ImageGrid = ({ imageOne, imageTwo, imageThree }: ImageGridProps) => {
  const numItems = !!imageThree ? 3 : 2;
  return (
    <Wrapper>
      <ImageGridItem numItems={numItems}>
        <SmallImage
          image={imageOne.image}
          size="half"
          altText={imageOne.altText}
        />
      </ImageGridItem>
      <ImageGridItem numItems={numItems}>
        <SmallImage
          image={imageTwo.image}
          size="half"
          altText={imageTwo.altText}
        />
      </ImageGridItem>
      {!!imageThree && (
        <ImageGridItem numItems={numItems}>
          <SmallImage
            image={imageThree.image}
            size="half"
            altText={imageThree.altText}
          />
        </ImageGridItem>
      )}
    </Wrapper>
  );
};

export default ImageGrid;
