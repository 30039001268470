import React from "react";

interface IFrameProps {
  iFrame: string;
}

const IFrame = ({ iFrame }: IFrameProps) => {
  return <div dangerouslySetInnerHTML={{ __html: iFrame }} />;
};

export default IFrame;
