import styled from "styled-components";
import { Typography, Weight, Space, Breakpoints } from "../../../settings";
import { breakpointUp } from "../../../util/breakpoint";

export const QuoteWrapper = styled.blockquote`
  text-align: center;
  margin: 0;
`;

interface TextProps {
  shrink: boolean;
}

export const QuoteText = styled.h3<TextProps>`
  margin-top: 0;

  font-size: ${p => (p.shrink ? Typography.default.rem : Typography.large.rem)};
  font-weight: ${Weight.regular};

  ${breakpointUp(Breakpoints.mobileLarge.upper)`
    font-size: ${Typography.large.rem};
  `}
`;

export const Attribution = styled.p`
  margin: 0;
`;

export const Company = styled.p`
  margin: 0;
  margin-top: ${Space.xSmall.rem};
  font-weight: ${Weight.medium};
`;
