import React, { useState, useEffect } from "react";
import Logo from "../Logo";
import { Color } from "../../../settings";
import { LogoWrapper, Wrapper, InnerWrapper } from "./styled";
import { Container } from "../Container/styled";
import Navigation from "../Navigation";
import { fade } from "../../../transition";
import TransitionLink from "gatsby-plugin-transition-link";
import { smoothScrollTo } from "../../../util/scrollTo";

interface HeaderProps {
  color: string;
  backgroundColor?: string;
  footerOffset: number;
}

const Header = ({ color, backgroundColor, footerOffset }: HeaderProps) => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  const toggleScroll = (showNav: boolean) => {
    if (showNav) {
      document.body.classList.add("no-scroll");
      smoothScrollTo(0);
    } else {
      document.body.classList.remove("no-scroll");
    }
  };

  const toggleNavigation = (showNav: boolean) => {
    setShowMobileNav(showNav);
    toggleScroll(showNav);
  };

  useEffect(() => {
    toggleScroll(showMobileNav);
  }, []);

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <Container as="header">
        <InnerWrapper>
          <TransitionLink
            exit={{
              length: 0.25,
              trigger: ({ exit, node }) =>
                fade({ exit, node, direction: "out" }),
            }}
            entry={{
              length: 0.25,
              trigger: ({ exit, node }) =>
                fade({ exit, node, direction: "in" }),
              delay: 0.25,
            }}
            to="/"
            style={{ display: "inline-block", textDecoration: "none" }}
          >
            <LogoWrapper>
              <Logo color={showMobileNav ? Color.black : color} zIndex={20} />
            </LogoWrapper>
          </TransitionLink>
          <Navigation
            color={showMobileNav ? Color.black : color}
            footerOffset={footerOffset}
            setShowMobileNav={toggleNavigation}
            showMobileNav={showMobileNav}
          />
        </InnerWrapper>
      </Container>
    </Wrapper>
  );
};

export default Header;
