import styled from "styled-components";
import { Space, Breakpoints } from "../../../settings";
import { breakpointDown, breakpointUp } from "../../../util/breakpoint";

export const Wrapper = styled.div`
  ${breakpointUp(Breakpoints.tabletPortrait.upper)`
    display: flex;
    align-items: flex-start;

  `}
`;

interface ImageGridItemProps {
  numItems: number;
}

export const ImageGridItem = styled.figure<ImageGridItemProps>`
  margin: 0;
  padding: 0;
  width: 100%;

  ${breakpointDown(Breakpoints.tabletPortrait.lower)`
    & + & {
      margin-top: ${Space.default.rem};
    }
  `}

  ${breakpointUp(Breakpoints.tabletPortrait.upper)`
    width: calc(
      ${p => 100 / p.numItems}% - ${p => Space.xLarge.number / p.numItems}rem
    );

    & + & {
      margin-left: ${Space.xLarge.rem};
    }
  `}
`;
