import styled from "styled-components";
import {
  Color,
  Space,
  Typography,
  Easing,
  Breakpoints,
} from "../../../settings";
import { breakpointUp, breakpointDown } from "../../../util/breakpoint";
import {
  LINK_UNDERLINE_STYLE,
  MOBILE_NAVIGATION_BREAKPOINT,
} from "../Navigation/styled";

export const FOOTER_BREAKPOINT = Breakpoints.tabletPortrait;

export const StyledFooter = styled.footer`
  position: relative;

  margin-top: ${Space.xxLarge.rem};
  padding: ${Space.xLarge.rem} 0;

  background-color: ${Color.black};
  color: ${Color.white};

  ${breakpointUp(FOOTER_BREAKPOINT.upper)`
    margin-top: ${Space.xxxLarge.rem};
    padding-bottom: ${Space.xxxLarge.rem};
  `}
`;

export const InnerWrapper = styled.div`
  ${breakpointUp(FOOTER_BREAKPOINT.upper)`
    display: flex;
    align-items: flex-start;
  `}
`;

export const TaglineWrapper = styled.div`
  ${breakpointDown(FOOTER_BREAKPOINT.lower)`
    margin-bottom: ${Space.large.rem};
  `}

  ${breakpointUp(FOOTER_BREAKPOINT.upper)`
    display: flex;
    align-items: top;
    width: calc(50% - ${Space.large.rem});
  `}
`;

export const ContactWrapper = styled.div`
  font-size: ${Typography.default.rem};

  div + div {
    margin-top: ${Space.xSmall.rem};
  }

  ${breakpointUp(FOOTER_BREAKPOINT.upper)`
    width: calc(50% - 1.5rem);
    margin-left: 3rem;
  `};
`;

export const Tagline = styled.p`
  margin: 0;
  margin-top: ${Space.large.rem};

  font-size: ${Typography.default.rem};

  ${breakpointUp(FOOTER_BREAKPOINT.upper)`
    margin-left: ${Space.large.rem};
    margin-top: 0;

    font-size: ${Typography.xLarge.rem};
  `}
`;

export const ContactLinkWrapper = styled.nav`
  & + & {
    margin-top: ${Space.small.rem};

    ${breakpointDown(MOBILE_NAVIGATION_BREAKPOINT.lower)`
      margin-top: ${Space.default.rem};
    `}
  }
`;

export const ContactLink = styled.a`
  color: inherit;
  position: relative;
  display: inline-block;

  ${LINK_UNDERLINE_STYLE};

  &:after {
    transform: scaleX(1);
    opacity: 1;
  }

  &:hover {
    &:after {
      transform: scaleX(0);
      opacity: 0;
    }
  }
`;

export const Address = styled.address`
  margin-top: ${Space.large.rem};

  font-style: normal;
`;

export const Arrow = styled.span`
  display: inline-block;
  width: 16px;

  color: ${Color.white};
  font-size: ${Typography.xLarge.rem};
  text-decoration: none;
  transition: transform ${Easing.slow}, opacity ${Easing.slow};

  .line {
    transform: scaleY(0.6);
    transition: transform ${Easing.slow};
  }
`;

export const ArrowWrapper = styled.span`
  position: absolute;
  top: ${Space.xLarge.rem};
  right: ${Space.xLarge.rem};
  width: ${Space.large.rem};
  z-index: 5;

  cursor: pointer;
  text-align: right;

  &:hover {
    ${Arrow} {
      transform: translateY(-10px);
      .line {
        transform: scaleY(1);
      }
    }
  }
`;

export const SocialWrapper = styled.nav`
  display: flex;

  margin-top: ${Space.large.rem};

  a {
    width: ${Space.large.rem};
    margin-right: ${Space.default.rem};
  }

  svg {
    transition: opacity ${Easing.default};

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 0.25;
    }
  }
`;
