import styled from "styled-components";
import { Space, Breakpoints } from "../../../settings";
import { breakpointDown, breakpointUp } from "../../../util/breakpoint";

const FLEX_MAPPING = {
  top: "flex-start",
  middle: "center",
  bottom: "flex-end",
};

interface WrapperProps {
  textAlign: "top" | "middle" | "bottom";
}

export const Wrapper = styled.div<WrapperProps>`
  ${breakpointUp(Breakpoints.tabletPortrait.upper)`
    display: flex;
    align-items: ${p => FLEX_MAPPING[p.textAlign]};
  `}
`;

interface FigureProps {
  align: "left" | "right";
}

export const Figure = styled.figure<FigureProps>`
  margin: 0;
  padding: 0;
  width: 100%;

  ${breakpointUp(Breakpoints.tabletPortrait.upper)`
    order: ${p => (p.align === "right" ? 1 : 0)};

    width: 66.6666%;
    margin-left: ${p => (p.align === "right" ? Space.xLarge.rem : 0)};
    margin-right: ${p => (p.align === "left" ? Space.xLarge.rem : 0)};
  `}
`;

export const Caption = styled.figcaption`
  margin-top: ${Space.default.rem};

  p {
    margin: 0;
  }

  ${breakpointUp(Breakpoints.tabletPortrait.upper)`
    width: calc(33.3333% - ${Space.xLarge.number / 3}rem);
    margin-top: 0;
  `}
`;
