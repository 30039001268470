import React from "react";
import Vimeo from "@u-wave/react-vimeo";
import { useInView } from "react-hook-inview";

interface VideoProps {
  videoId: string;
  loop: boolean;
  muted: boolean;
}

const Video = ({ videoId, loop, muted }: VideoProps) => {
  const [ref, isVisible] = useInView({
    unobserveOnEnter: false,
    threshold: 0.2,
  });

  return (
    <div ref={ref}>
      <Vimeo
        video={videoId}
        autoplay={false}
        controls={false}
        paused={!isVisible}
        width="100%"
        loop={loop}
        responsive={true}
        muted={muted}
      />
    </div>
  );
};

export default Video;
