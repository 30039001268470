import styled, { css } from "styled-components";
import {
  Typography,
  Color,
  Space,
  Weight,
  Easing,
  Breakpoints,
} from "../../../settings";
import { TransitionStatus } from "react-transition-group/Transition";
import { breakpointDown, breakpointUp } from "../../../util/breakpoint";
import TransitionLink from "gatsby-plugin-transition-link";

export const MOBILE_NAVIGATION_BREAKPOINT = Breakpoints.mobileLarge;

export const NavItems = styled.ul`
  padding: 0;
  margin: 0;

  ${breakpointUp(MOBILE_NAVIGATION_BREAKPOINT.upper)`
    display: flex;
  `};
`;

export const NavItem = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;

  ${breakpointUp(MOBILE_NAVIGATION_BREAKPOINT.upper)`
    & + & {
      margin-left: ${Space.default.rem};
    }
  `};

  ${breakpointDown(MOBILE_NAVIGATION_BREAKPOINT.lower)`
    display: block;
    text-align: center;
    width: 100%;
    
    & + & {
      margin-top: ${Space.default.rem};
    }
  `}
`;

interface LinkProps {
  isMatch: boolean;
  color: string;
}

export const LINK_UNDERLINE_STYLE = css`
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;

    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;

    background-color: currentColor;
    opacity: 0;
    transform: scaleX(0);
    transform-origin: 0;
    transition: transform ${Easing.default}, opacity ${Easing.slow};
  }

  &:hover {
    &:after {
      transform: scaleX(1);
      opacity: 1;
    }
  }
`;

export const StyledLink = styled(TransitionLink)<LinkProps>`
  display: inline-block;
  position: relative;

  color: ${p => p.color};
  cursor: pointer;
  font-size: ${Typography.default.rem};
  font-weight: ${Weight.bold};
  text-decoration: none;
  text-transform: uppercase;

  ${LINK_UNDERLINE_STYLE};

  ${p =>
    p.isMatch &&
    `
      &:after {
        transform: scaleX(1);
        opacity: 1;
      }
  `}

  ${breakpointDown(MOBILE_NAVIGATION_BREAKPOINT.lower)`
    color: ${Color.black};
    font-size: ${Typography.large.rem};

    &:after {
      background-color: ${Color.black};
    }
  `};
`;

interface MobileNavigationWrapperProps {
  transitionStatus: TransitionStatus;
}

export const MobileNavigationWrapper = styled.nav<MobileNavigationWrapperProps>`
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;

  background-color: ${Color.lightBuff};
  transform: ${p =>
    p.transitionStatus === "entered" ? "translateX(0)" : "translateX(100%)"};
  transition: transform ${Easing.default};
`;

export const MobileNavigationInnerWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 90%;
  height: calc(100% - ${Space.large.rem});
  margin: 0 auto;

  // Mobile navigation
  & > *:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-grow: 2;
    flex-wrap: wrap;
  }
`;

export const ContactFooter = styled.footer`
  color: ${Color.black};
  font-size: ${Typography.large.rem};
  text-align: center;
`;
